/*
 * @NOTE: Prepend a `~` to css file paths that are in your node_modules
 *        See https://github.com/webpack-contrib/sass-loader#imports
 */
@import '~@fortawesome/fontawesome-free/css/all.css';

* {
    box-sizing: border-box;
}

body {
  position: relative;
  color: gray;
  background-color: #f8f8f8;

  /* background-image: linear-gradient(45deg, rgba(0, 216, 255, 0.5) 10%, rgba(0, 1, 127, 0.7)); */
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

h2 {
  margin: 0;
  font-size: 2.25rem;
  font-weight: bold;
  letter-spacing: -0.025em;
  color: gray;
}

p {
  font-size: 24px;
}

li {
  list-style: none;
}

a {
  color: gray;
  opacity: 0.75;
  text-decoration: none;
}

a:hover {
  opacity: 1;
  text-decoration: none;
  cursor: pointer;
}
